 import styled from "styled-components";
 import { borderRaduis } from "../theme/standardTheme";

 const InputWrapper = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;
    background-color: ${props => props.color? props.color: "red"};
    border-radius: ${borderRaduis.large};
    padding: 8px;
 `
 export default InputWrapper;
