import styled from "styled-components";
import {ReactComponent as SVG} from '../icons/info.svg'
import { color } from "../theme/standardTheme";

const InfoIcon = styled(SVG)`
    path {
        stroke: ${props => props.color? props.color : color.font}; 
    };
    circle {
        stroke: ${props => props.color? props.color : color.font}; 
    }
    display: inline; 
    justify-self: center; 
    align-self: center;
    height: 14px;
    width: 14px;
`
export default InfoIcon;