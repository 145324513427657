  import styled from "styled-components";

 const BoubleModal = styled.div`
    display: ${props => props.show? 'flex' : 'none'};
    position: absolute; /* Stay in place */
    z-index: 1000; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100vw; /* Full width */
    height: 100vh; /* Full height */
    background-color: ${props => props.color? props.color : "rgb(0,0,0)"}; /* Fallback color */
    background-color: ${props => props.color? props.color : "rgba(50,50,50,0.8)"}; /* Black w/ opacity */
    overflow: visible;
 `
 export default BoubleModal;
