import styled from "styled-components";

const SliderWrapper = styled.div`
    display: flex;
    justify-content: center; 
    align-items: center; 
    grid-column-start: 1;
    grid-column-end: 3;
`

const PermDistributionContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: 100%;
    max-width: 400px;
    min-width: 300px;
`

export {
    SliderWrapper, 
    PermDistributionContainer
}
