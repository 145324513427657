import styled from "styled-components";
import {ReactComponent as SVG} from '../icons/ok-circle.svg'
import { color } from "../theme/standardTheme";

const OkIcon = styled(SVG)`
    fill: ${props => props.color? props.color : color.font}; ;
    path {
        stroke: ${props => props.color? props.color : color.font}; 
    };
    display: inline; 
    justify-self: center; 
    align-self: center;
    height: 26px;
    width: 26px; 
`
export default OkIcon;