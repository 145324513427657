import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Select from 'react-select'

const StyledSelect = styled(Select)`
    width: 170px; 

`

const DropdownMenuWrapper = styled.div`
    z-index: 20;
`

const DropdownMenu = (props) => {

    const options = [
        {
            value: 'mamma',
            label: 'Mammaperm'
        },{
            value: 'pappa',
            label: 'Pappaperm'
        },{
            value: 'felles',
            label: 'Fellesperm'
        },{
            value: 'mammaFerie',
            label: 'Mor Ferie'
        },{
            value: 'pappaFerie',
            label: 'Far Ferie'
        },{
            value: 'beggeFerie',
            label: 'Begge Ferie'
        },{
            value: 'mammaUlonnetPerm',
            label: 'Mor Ulønnet Perm'
        },{
            value: 'pappaUlonnetPerm',
            label: 'Far Ulønnet Perm'
        },
    ];

    const [selected, setSelected] = useState(options[5]);

    useEffect(() => {
        if (props.select){
            var defaultSelect = options.find(option => option.value === props.select)
            setSelected(defaultSelect);
        }
    }, []);

    function onSelect(item) {
        props.setPermType(item);
        setSelected(item);
    }

    return (
        <DropdownMenuWrapper style={props.style}>
            <StyledSelect
                options={options}
                value={selected}
                placeholder={"Type permisjon"}
                onChange={onSelect}
            />
        </DropdownMenuWrapper>
    )
}

export default DropdownMenu;
