import styled from "styled-components";
import { borderRaduis, color } from "../theme/standardTheme";

const InnerModalContainer = styled.div`
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    padding: 10px; 
    background-color: ${color.burgundy};
    border-radius: ${borderRaduis.large};
    min-width: 300px;
    min-height: 100px; 
    width: 50%;
    box-shadow: 0px 0px 20px ${color.fontSecondary};
    
`

export default InnerModalContainer;