import React, { useEffect, useState } from "react";
import { InputFieldContainer, InputLabel, InputArea } from "./InputFieldStyles";
import { H5 } from "../../theme/text";
import { color } from "../../theme/standardTheme";

const InputField = (props) => {

    const [localValue, setLocalValue] = useState(props.value);

    useEffect(() => {
        setLocalValue(props.value);
    }, [props.value]);

    function refresh(val) {
        setLocalValue(val);
    }

    function update(val) {
        setLocalValue(props.value);
        props.update(val);
    }

    return (
        <InputFieldContainer maxWidth={props.maxWidth} style={props.style}>
            {props.label?<H5 style={{margin: "0px 10px", justifySelf: "end", color: color.fontSecondary}}>{props.label}</H5>:<></>}
            <InputArea
                type = {props.type}
                pattern={props.pattern}
                onChange={(event) => {refresh(event.target.value)}}
                onBlur = {(event) => {update(event.target.value)}}
                value={localValue}
                onClick={() => refresh('')}
                maxWidth = {props.maxWidth}
                placeholder = "0"
            />
        </InputFieldContainer>
    );
}

export default InputField;