import styled from "styled-components";

const Calendar = styled.div`
    display: grid; 
    gird-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(9, 69px);
    grid-auto-rows: 72px;
    min-width: 10px;  
    min-height: 10px;
    row-gap: 5px;
    column-gap: 5px;
    padding: 5px;

    @media (max-width: 750px) {
        display: flex; 
        flex-direction: column;

    }
`

export default Calendar;
