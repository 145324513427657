import styled from "styled-components";
import { color } from "../theme/standardTheme";

const EconomyResultContainer = styled.div`
    display: grid; 
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;
    grid-auto-flow: row; 
    gap: 10px;
    background-color: ${color.secondary}; 

    @media (max-width: 700px) {
        display: flex;
        flex-direction: column; 
    }

`

/*
        display: flex; 
        flex-direction: column;
        justify-content: center; 
        aling-items: center; 
*/
export default EconomyResultContainer;