import styled from "styled-components";
import { borderRaduis } from "../../theme/standardTheme";

const ThemeSelectorContainer = styled.div`
    display: grid; 
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-start: 1;
    grid-row-start: 1;
    border-radius: ${borderRaduis.large};
    overflow: hidden;
`

export default ThemeSelectorContainer;