 import React from "react";
 
 import styled from "styled-components";

 const Modal = styled.div`
    display: ${props => props.show? 'flex' : 'none'};
    flex-direction: column; 
    justify-content: start; 
    align-items: center; 
    padding-top: 10%; 
    position: absolute; /* Stay in place */
    z-index: 1000; /* Sit on top */
    width: 100vw; /* Full width */
    height: 100vh; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: ${props => props.color? props.color : "rgb(0,0,0)"}; /* Fallback color */
    background-color: ${props => props.color? props.color : "rgba(0,0,0,0.0)"}; /* Black w/ opacity */
 `
 export default Modal;
