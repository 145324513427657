import styled from "styled-components";
import { borderRaduis, color, font } from "../theme/standardTheme";
import { H5, H4 } from "../theme/text";
import { useState } from "react";
import TopicButton from "./Buttons/TopicButton";
import Modal from "../containers/Modal";

const height = 100; 
const width = 250;

const StyledInfoBouble = styled.div`
    position: relative;
    top: ${props => props.top? props.top : "50%"}; 
    left: ${props => props.left? props.left : "50%"};
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    max-width: ${width}px; 
    max-height: ${height}px; 
    min-width: ${width}px; 
    min-height: ${height}px; 
    border-radius: ${borderRaduis.medium};
    background-color: ${color.burgundy};
    color: ${color.fontSecondary};
    font-family: ${font};
    box-shadow: 12px 12px;
    padding: 25px 15px; 
    z-index: 90000000000; 

    &: before {
        content: "";
        position: absolute;
        top: -8%;
        left: 204px;
        width: 0;
        border-bottom: 12px solid ${color.burgundy};
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
    }
`

const RemoveLayer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 800000000;
`

const InfoBouble = (props) => {



    const infoText = [
        `
            Nyfødte begynner å fokusere på ansikter og kan reagere på foreldres stemmer.
        `,
        `
            Smil blir vanligere, og babyer begynner å “kose” med lyder.
        `,
        `
            Babyer løfter hodet med mer kontroll og følger bevegelige objekter med øynene.   
        `,
        `
            Ler høyt, kan gripe etter leker og har mer regelmessige søvnmønstre.
        `,
        `
            Begynner å rulle fra magen til ryggen og utviser nysgjerrighet for omverdenen.
        `,
        `
            Mange babyer begynner å sitte uten støtte og kan vise tegn på å være klar for fast føde.   
        `,
        `
            Kryping kan starte, og babyer lærer å overføre objekter fra en hånd til en annen.   
        `,
        `
            Utrykker tydelige liker og misliker, kan stå med støtte.
        `,
        `
            Har favoritt leker, forstår enkel "ja" og "nei", kan begynne å stå uten hjelp.
        `,
        `
            Kan vinke "ha det", krabbe effektivt, og noen begynner å uttale enkle ord.
        `,
        `
            Viser preferanse for visse personer, utforsker mer aktivt med fingrene.   
        `,
        `
            Mange barn tar sine første skritt, har enkle ord for foreldre, og forstår enkle instruksjoner.
        `,
        `
            Kan drikke fra en kopp, leker "gi og ta" spill.
        `,
        `
            Går mer sikkert, begynner å bruke gjenstander etter deres hensikt (f.eks. holder en telefon til øret).
        `,
        `
            Spiser mer selvstendig, kan peke på kroppsdeler når navnet nevnes.
        `,
        `
            Avanserte gangferdigheter, viser interesse i å etterligne voksenaktiviteter.
        `,
        `
            Kan si flere ord, og sette sammen enkle setninger.
        `,
        `
            Økende selvbevissthet, kan hjelpe i husarbeidet på enkle måter, som å plukke opp leker.   
        `
    ];
    
    return(<>
        {(props.age > 0 && props.age <= 18)?<>
            <StyledInfoBouble onClick={props.show} top={props.top} left={props.left}>
                <H4>Barnet fyller {props.age} måneder!</H4>
                {infoText[props.age - 1]} 
                <a style={{fontWeight: "bold", color: color.font, marginTop: "20px" }} href="https://www.foreldrepenger.no/barnetsutvikling" target="_blank">Les mer</a>
            </StyledInfoBouble>
            <RemoveLayer onClick={props.hide}/>
        </>:<></>}
    </>)

}

export default InfoBouble