import styled from "styled-components";


const HorizontalChartWrapper = styled.div`
    display: flex; 
    flex-direction: row; 
    justify-content: space-evenly; 
    align-items: center; 
    width: 100%;
`

export default HorizontalChartWrapper;
