import React from "react";
import styled from "styled-components";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, Filler, Legend } from 'chart.js';
ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, Filler, Legend);


const LineChartContainer = styled.div`
    position: relative; 
    height: 280px;
    width: 98%;
    margin: 10px;
    max-width: 600px;
    
    @media (max-width: 700px) {
      max-width: 500px; 
    }
    @media (max-width: 550px) {
      max-width: 400px; 
    }
    @media (max-width: 440px) {
      max-width: 350px; 
    }
    @media (max-width: 390px) {
      max-width: 300px; 
    }
`

const LineChart = (props) => {

    const data = {
        labels: props.x,
        datasets: [
          {
            label: '100%',
            data: props.y1,
            borderColor: (props.color1 || "blue"),
            backgroundColor: (props.color1 || "blue"),
            fill: false,
          },
          {
            label: '80%',
            data: props.y2,
            borderColor: (props.color2 || "red"),
            backgroundColor: (props.color2 || "red"),
            fill: false,
          },
        ],
      };
    
      // Chart options
      var options = {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        elements: {
          point:{
              radius: 0
          }
        },
        scales: {
          y: {
            display: true,
            beginAtZero: true,
            grid: {
              display: true,
              color: 'white',
            },
          },
          x: {
            display: true, 
            grid: {
              display: false, 
              color: 'white',
            }
          }
        },
        plugins: {
          title: {
            display: true,
            text: props.title,
            color: props.titleColor || "black",
          },
          legend: {
              display: true,
              labels: {
                  color: props.titleColor || "black",
                  boxHeight: 1,
              }
          }
        }
      };

    return (
        <LineChartContainer>
            <Line data={data} options={options}></Line>
        </LineChartContainer>
    );
}

export default LineChart;