import styled from "styled-components";
import { color, font  } from "../../theme/standardTheme";

const MonthName = styled.div`
    background-color: ${props => props.color? props.color : "red"};
    height: 10px;
    grid-row-start: 1; 
    grid-row-end: 2; 
    grid-column-start: ${props => props.start? (props.start): 32};;
    grid-column-end: ${props => props.end? (props.end): 32};
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 5px;
    color: ${color.fontSecondary};
    font-family: ${font};
`

export default MonthName;