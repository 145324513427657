import React, { useEffect, useState } from "react";
import MonthContainer from "./MonthContainer";
import MonthName from "./MonthName";
import DateNumber from "./DateNumber";
import PermSectionBar from "../PermSectionBar";
import { permSectionColors, getPermColor, exactSameDate, getMonthDiff } from "../../utils/permSectionUtils";
import TermDateMarker from "../TermDateMarker";
import InfoBouble from "../InfoBouble";
import InfoIcon from "../../components/InfoIcon";
import { color } from "../../theme/standardTheme";
import BoubleModal from "../../containers/BoubleModal";


const Month = (props) => {

    const [ageString, setAgeString] = useState('');
    const [showBouble, setShowBouble] = useState(false);
    const [boubleTop, setBoubleTop] = useState(0);
    const [boubleLeft, setBoubleLeft] = useState(0);

    useEffect(() => {
        if (props.checkDate && props.termDate){
            var monthDiff = getMonthDiff(props.termDate, props.checkDate);
            var aS = '';
            if (monthDiff === -1) aS = 'Snart kommer barnet'
            else if (monthDiff === 0) aS = 'Barnet blir født'
            else if (monthDiff === 1) aS = `Barnet fyller ${monthDiff} måned`
            else aS = `Barnet fyller ${monthDiff} måneder`
            setAgeString(aS)
        }
    },[props.checkDate, props.termDate]);

    function toggleShowBouble(event) {
        console.log(event);
        var X = `${event.pageX - 215}px`
        var Y = `${event.pageY - 77}px`
        setBoubleTop(Y);
        setBoubleLeft(X);
        setShowBouble(!showBouble);
    }


    return (
        <>
            <MonthContainer col={props.col} row={props.row} color={props.color} fr={props.fr}>
                <MonthName
                    color={props.color}
                    start={1}
                    end={-1}>
                        {props.monthName}, {props.year}  -  {ageString}
                        &nbsp;
                        {(props.age >= 1 && props.termDate && true)?
                            <InfoIcon
                                color={color.fontSecondary}
                                onClick={event => toggleShowBouble(event)}
                                onBlur={()=> setShowBouble(false)}
                            />
                        :<></>}
                </MonthName>
                {Array.from(Array(props.fr).keys()).map((day)=> {
                    var date = day+1;
                    
                    return (
                        <>
                            <DateNumber col={date} color={props.color}>{date}</DateNumber>
                            {props.sections?<>
                                {props.sections.map((section, ix) => {
                                    var color = getPermColor(section.type);
                                    var name = section.name; 
                                    if((section.endDate.getDate() - section.startDate.getDate()) < 10) name = '';
                                    
                                    var checkDate = new Date(section.endDate.getFullYear(), section.endDate.getMonth(), section.endDate.getDate()+1, 13);
                                    var term = new Date(props.termDate.getFullYear(), props.termDate.getMonth(), props.termDate.getDate(), 13)
                                    var includeTermDate = exactSameDate(checkDate, term);

                                    var termStart; 
                                    var termEnd;
                                    if (includeTermDate) {
                                        if (props.termDate.getDate() === 1){includeTermDate = false;}
                                        termStart = props.termDate.getDate() - 1;
                                        termEnd = props.termDate.getDate() + 2;
                                    }

                                    return(<>
                                            <PermSectionBar
                                                key={section.id}
                                                color={color}
                                                colStart={section.startDate.getDate()}
                                                colEnd={section.endDate.getDate()}
                                                radiusLeft={section.radiusLeft}
                                                radiusRight={section.radiusRight}
                                            >{name}</PermSectionBar>
                                            {includeTermDate?
                                            <TermDateMarker
                                                start={termStart}
                                                end = {termEnd}
                                            />
                                            :<></>}
                                        </>
                                    )
                                })}
                            </>
                            : <></>}

                            {props.addTermDate?
                                <TermDateMarker
                                    start={0}
                                    end = {1}
                                    firstDate={true}
                                />    
                            :<></>}
                        </>
                    )
                })}
            </MonthContainer>
            <BoubleModal color="none" show={showBouble}>
                <InfoBouble show={() => setShowBouble(false)} age={props.age} top={boubleTop} left={boubleLeft} hide={() => setShowBouble(false)}/>
            </BoubleModal>
        </>
    )
}

export default Month; 