import styled from "styled-components";

const PlaningViewContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    min-height: 100px; 
    min-width: 100px; 
    column-gap: 5px; 
    

    @media (max-width: 1220px) {
        display: flex; 
        flex-direction: column;
    }


`
//padding: 5px; 
export default PlaningViewContainer;
