import React, { useState } from "react";
import InputContainer from "../containers/InputContainer";
import InputField from "./Fields/InputField";
import CoParrentField from "./Fields/CoParrentField";
import DividerLine from "./DividerLine";
import Slider from "./Slider";
import { P, H1, H2, H3, H4, H5 } from "../theme/text";
import { perm100Grenser, perm80Grenser } from "../utils/permisjonsUtils";
import { PermDistributionContainer, SliderWrapper} from '../containers/PermDistributionContainer'
import { color } from "../theme/standardTheme";
import InfoIcon from "./InfoIcon";
import Modal from "../containers/Modal";
import InnerModalContainer from "../containers/InnerModalContainer";
import PermShareInnerModal from "./PermShareInnerModal";

const EconomyInput = (props) => {

    const [showPermShareModal, setShowPermShareModal] = useState(false);
    const [showSalaryModal, setShowSalaryModal] = useState(false);

    function updatePaymentsMom(sal) {
        sal = Number(sal);
        if (sal < 0) sal = 0; 
        var permSal = sal;
        if (permSal > 42000) permSal = 42000;
        var updatedPayments = {...props.payments}
        updatedPayments.mor = sal;
        updatedPayments.ukeMor = sal*updatedPayments.ukeFaktor;
        updatedPayments.permMor = permSal;
        updatedPayments.permUkeMor = permSal*updatedPayments.ukeFaktor;
        props.setPayments(updatedPayments);
    }

    function updatePaymentsDad(sal) {
        sal = Number(sal);
        if (sal < 0) sal = 0; 
        var permSal = sal;
        if (permSal > 42000) permSal = 42000;
        var updatedPayments = {...props.payments}
        updatedPayments.far = sal;
        updatedPayments.ukeFar = sal*updatedPayments.ukeFaktor;
        updatedPayments.permFar = permSal;
        updatedPayments.permUkeFar = permSal*updatedPayments.ukeFaktor;
        props.setPayments(updatedPayments);
    }

    function updateFerieFar(uker){
        uker = Number(uker);
        props.updatePerm100.ferieFar(uker);
        props.updatePerm80.ferieFar(uker);
    }

    function updateFerieMor(uker) {
        uker = Number(uker);
        props.updatePerm100.ferieMor(uker);
        props.updatePerm80.ferieMor(uker);
    }

    function updateWeeksNoPayDad(uker) {
        uker = Number(uker);
        props.updatePerm100.ulonnetPermFar(uker);
        props.updatePerm80.ulonnetPermFar(uker);
    }

    function updateWeeksNoPayMom(uker) {
        uker = Number(uker);
        props.updatePerm100.ulonnetPermMor(uker);
        props.updatePerm80.ulonnetPermMor(uker);
    }

    function update100Split(dadShare){
        var ds = Number(dadShare);
        var totalDad = ds + perm100Grenser.farUker;
        var totalMom = (perm100Grenser - ds) + perm100Grenser.morUker  + perm100Grenser.fodselsUker;
        props.updatePerm100.ukerMor(totalMom);
        props.updatePerm100.ukerFar(totalDad); 
    }

    function update80Split(dadShare){
        var ds = Number(dadShare);
        var totalDad = ds + perm80Grenser.farUker;
        var totalMom = (perm80Grenser - ds) + perm80Grenser.morUker  + perm80Grenser.fodselsUker;
        props.updatePerm80.ukerMor(totalMom);
        props.updatePerm80.ukerFar(totalDad); 
    }


    return(<>
        <InputContainer color={color.secondary}>
            <H4 style={{color: color.font}}>Fordeling av permisjonsuker  <InfoIcon onClick={() => setShowPermShareModal(true)}/></H4>
            <P>Fordel permisjonsukene ved 100% lønnsutbetaling? </P>
            <PermDistributionContainer>
                <SliderWrapper>
                    <Slider
                        update = {update100Split}
                        min= {0}
                        max = {perm100Grenser.fellesUker}
                        defaultValue = {Math.round(perm100Grenser.fellesUker/2)}
                    />
                </SliderWrapper>
                <P style={{justifySelf: "start"}}>Uker Mor: {props.perm100.ukerMor}</P>
                <P style={{justifySelf: "end"}}>Uker Far: {props.perm100.ukerFar}</P>
            </PermDistributionContainer>
            {/*<DividerLine color={props.color.secondary}/>*/}
            <P>Fordel permisjonsukene ved 80% lønnsutbetaling? </P>
            <PermDistributionContainer>
                <SliderWrapper>
                    <Slider
                        update = {update80Split}
                        min= {0}
                        max = {perm80Grenser.fellesUker}
                        defaultValue = {Math.round(perm80Grenser.fellesUker/2)}
                    />
                </SliderWrapper>
                <P style={{justifySelf: "start"}}>Uker Mor: {props.perm80.ukerMor}</P>
                <P style={{justifySelf: "end"}}>Uker Far: {props.perm80.ukerFar}</P>
            </PermDistributionContainer>
            <DividerLine color={props.color.secondary}/>
            <PermDistributionContainer>
                <H4 style={{color: color.font, gridColumnStart: 1, gridColumnEnd: 3, justifySelf: "center"}}>Månedlig lønn etter skatt  <InfoIcon onClick={() => setShowSalaryModal(true)}/></H4>
                    <InputField
                        update = {updatePaymentsMom}
                        value = {props.payments.mor}
                        label = {"Mor:"}
                        maxWidth = {"130px"}
                        style = {{justifySelf: "center"}}
                    /> 
                    <InputField
                        update = {updatePaymentsDad}
                        value = {props.payments.far}
                        label = {"Far:"}
                        maxWidth = {"130px"}
                        style = {{justifySelf: "center"}}
                    />
            </PermDistributionContainer>
            <DividerLine color={props.color.secondary}/>
            <PermDistributionContainer>
                <H4 style={{color: color.font, gridColumnStart: 1, gridColumnEnd: 3, justifySelf: "center", margin: "0px"}}>Uker ferie</H4>
                <P style={{color: color.fontSecondary, gridColumnStart: 1, gridColumnEnd: 3, justifySelf: "center", margin: "0px"}}>Husk å legg inn for begge sider av nyttår</P>
                <InputField
                    update = {updateFerieMor}
                    value = {props.perm100.ferieMor}
                    label = {"Mor:"}
                    maxWidth = {"130px"}
                    style = {{justifySelf: "center", marginTop: "12px"}}
                />
                <InputField
                    update = {updateFerieFar}
                    value = {props.perm100.ferieFar}
                    label = {"Far:"}
                    maxWidth = {"130px"}
                    style = {{justifySelf: "center", marginTop: "12px"}}
                />
            </PermDistributionContainer>
            <DividerLine color={props.color.secondary}/>
            <PermDistributionContainer>
                <H4 style={{color: color.font, gridColumnStart: 1, gridColumnEnd: 3, justifySelf: "center", margin: "0px"}}>Uker ulønnet permisjon</H4>
                <InputField
                    update = {updateWeeksNoPayMom}
                    value = {props.perm100.ulønnetPermMor}
                    label = {"Mor:"}
                    maxWidth = {"130px"}
                    style = {{justifySelf: "center"}}
                />
                <InputField
                    update = {updateWeeksNoPayDad}
                    value = {props.perm100.ulønnetPermFar}
                    label = {"Far:"}
                    maxWidth = {"130px"}
                    style = {{justifySelf: "center"}}
                />
            </PermDistributionContainer>
        </InputContainer>
        <Modal show={showPermShareModal} onClick={() => setShowPermShareModal(false)}>
            <InnerModalContainer>
                <H4>Hvordan fordele permisjon</H4>
                <P>
                    Hvordan man velger å fordele permisjonen er veldig individuallt og kommer helt an på familiens situasjon
                </P>
                <P>
                    Det er mange faktorer som spiller inn når man skal fordele permisjonen, blandt annet kan deres privatøkonomi og prefferanser ha stor betydning
                    En ting som er viktig å huske på er at dens om ikke er i permisjon er i arbeid og tjener penger som normalt. 
                    Dette vil spille inn på totaløkonomien deres dersom dere velger 80% utbetaling eller kompenserer med ulønnet permisjon.
                    I de fleste tilfeller krever det mer av økonomien deres å velge 80% utbetaling fordi de siste ukene har man kun en i vanlig jobb 
                    der man vil ha to i jobb de siste ukene dersom man velger 100% utbetaling. Til gjengleld frigjør man mer tid med barnet. 
                </P>
                <P>
                    Denne permisjonskalkulatoren er her for å hjelpe dere med planleggingen slik at dere finner den permisjonen som er best for dere.
                    Vi har også en artikkel der vi skrier mer om hva man bør ta hensyn til når man fordeler permisjon og hvilken betydning dette har for økonomien deres. 
                </P>
                <a style={{fontWeight: "bold", color: color.font, marginTop: "20px" }} href="https://www.foreldrepenger.no/fordele-permisjon" target="_blank">Les mer</a>
            </InnerModalContainer>
        </Modal>
        <Modal show={showSalaryModal} onClick={() => setShowSalaryModal(false)}>
            <PermShareInnerModal />
        </Modal>
        </>);
}

export default EconomyInput;