import styled from "styled-components";
import {ReactComponent as SVG} from '../icons/Heart.svg'
import { color } from "../theme/standardTheme";

const TermDateMarker = styled(SVG)`
    path {
        fill: ${color.termMarker}; 
    };
    circle {
        fill: ${color.termMarker};
    };
    display: flex; 
    justify-self: center; 
    align-self: center;
    height: ${props => props.firstDate? "10px": "20px"};
    width: ${props => props.firstDate? "10px": "20px"};; 
    grid-column-start: ${props => props.start? (props.start): 1};
    grid-column-end: ${props => props.end? (props.end): 10};
    grid-row-start: 3;
    grid-row-end:  4;
    z-index: 10;
`
export default TermDateMarker;
