import React from "react";
import styled from "styled-components";

const ThemeTile = styled.div`
    height: 40px;
    width: 40px;
    grid-column-start: ${props => props.$columnStart? props.$columnStart : 1};
    grid-column-end: ${props => props.$columnEnd? props.$columnEnd: 2};
    grid-row-start: ${props => props.$rowStart? props.$rowStart : 1};
    grid-row-end: ${props => props.$rowEnd? props.$rowEnd: 2};
    background-color: ${props => props.color? props.color: "red"};

`

export default ThemeTile;