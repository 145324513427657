import styled from "styled-components";

const DividerLine = styled.hr`
    grid-column-start: 1;
    grid-column-end: 4;
    border-top: 4px solid ${props => props.color? props.color : "black"};
    border-radius: 2px;
    width: 80%;
`

export default DividerLine;