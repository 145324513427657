import styled from "styled-components";
import { borderRaduis } from "../theme/standardTheme";

const PeriodEditorContainer = styled.div`
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    background-color: ${props => props.color? props.color : "red"};
    border-radius: none;
    padding: 10px; 
    align-self: stretch;
    justify-self: stretch;
`
//border-radius: ${borderRaduis.medium};

export default PeriodEditorContainer;