import styled from "styled-components";
import { borderRaduis } from "../theme/standardTheme";

const InputContainer = styled.div`
    display: flex;
    flex-direction: column; 
    justify-items: center; 
    align-items: center;
    padding: 10px;
    background-color: ${props => props.color? props.color : "red"};
`
export default InputContainer;