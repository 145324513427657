import styled from "styled-components";
import { borderRaduis, color } from "../theme/standardTheme";

const ResultContainer = styled.div`
    display: flex; 
    flex-direction: column; 
    justify-content: start;
    align-items: center; 
    grid-column-start: ${props => props.cs? props.cs : 0};
    grid-column-end: ${props => props.ce? props.ce : 0};
    grid-row-start: ${props => props.rs? props.rs : 0};
    grid-row-end: ${props => props.re? props.re : 0};
    background-color: ${color.primary};
    border-radius: ${borderRaduis.medium};
    padding: 10px;
`
export default ResultContainer;