import React from "react";
import InnerModalContainer from "../containers/InnerModalContainer";
import { H4, P } from "../theme/text";
import { color } from "../theme/standardTheme";
import { grunnbelopet } from "../utils/permisjonsUtils";

const PermShareInnerModal = (props) => {

    return(
        <InnerModalContainer>
            <H4>Hva kan du få utbetalt</H4>
            <P>
                Om man velger 100% lønnsutbetaling vil man i prinsippet få full lønn dekket dersom man velger 100% utbetalingsmodell
                og om man velger 80% utbetalingsmodell får man 80% av lønnen sin. 
            </P>
            <P>
                Permisjonspengene utbetales den 25. hver måned. 
            </P>
            <P>
                Hvor mye man kan få utbetalt kan variere med situasjonen din men normalt har man krav på 6 ganger grunnbeløpet. 
                I dag er det på {grunnbelopet} som betyr at NAV dekker lønn opp til {6*grunnbelopet} i form av foreldre penger. 
                Dersom du tjener mer enn dette vil du ikke få støtte for det som er over {6*grunnbelopet}. 
            </P>
            <P>
                Mange arbeidsgivere kompenserer for dette tapet ved å utbetale delvis lønn i permisjonsperioden slik at 
                du har det samme å leve for gjennom permisjonstiden. Dette er noe du selv må høre med din arbeidsgiver om. 
            </P>
            <a style={{fontWeight: "bold", color: color.font, marginTop: "20px" }} href="https://www.foreldrepenger.no/lønn-og-permisjonspenger" target="_blank">Les mer</a>
        </InnerModalContainer>
    )
}

export default PermShareInnerModal;