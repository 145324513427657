import React, { useEffect, useState } from "react";
import PlaningViewContainer from "../containers/PlaningViewContainer";
import PlaningEditorContainer from "../containers/PeriodEditorContainer";
import Calendar from "../components/Calendar/Calendar";
import Month from "../components/Calendar/Month";
import RadioCheckboxContainer from "../components/PlanningInput/RadioCheckboxContainer";
import Checkbox from "../components/Checkbox";
import { H4, H5, P } from "../theme/text";
import DateSelector from "../components/DateSelector";
import { monthData, getPermSections, buildPeriod, shiftDate, getNextDate, getPermColor, getMonthDiff } from "../utils/permSectionUtils";
import AddSectionTool from "../components/AddSectionTool";
import Period from "../components/Period";
import { perm100Grenser, perm80Grenser } from "../utils/permisjonsUtils";
import Modal from "../containers/Modal";
import InnerModalPermSections from "../components/InnerModalPermSections";


const PlanningView = (props) => {

    var previousYear; 
    var permYear;
    var nextYear;
    var nextNextYear;
    

    const [months, setMonths] = useState(new Array(18).fill({}));
    const [model100, setModel100] = useState(true);
    const [termDate, setTermDate] = useState('');
    const [sections, setSections] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [showPlannerInfo, setShowPlannerInfo] = useState(false);

    useEffect(() => {
        updateMonthOrder();
    }, [])

    function updateMonthOrder(startAt = 0) {
        if (startAt < 0 || 11 < startAt) startAt = 1;
        var mtnNum = startAt; 
        var mnts=[];
        var nextYearCount = 0;
        for (let i=0; i<20; i++) {
            
            var mnt = {
                number: i,
                name: monthData[mtnNum].name,
                numberOfDays: monthData[mtnNum].numberOfDays,
                calendarNumber: monthData[mtnNum].number,
            }
            
            if (mnt.calendarNumber===12 && mnt.number===0) {
                mnt.year = previousYear; 
            }
            else if(mnt.calendarNumber >= mnt.number) {
                mnt.year = permYear;
            } else {
                nextYearCount++;
                if (nextYearCount > 12) {
                    mnt.year = nextNextYear;
                } else {
                    mnt.year = nextYear;
                }
            }
            
            
            mnts.push(mnt);
            mtnNum++;
            if (mtnNum > 11) mtnNum=0;
        }
        setMonths(mnts)
    }

    function getPeriodId(){
        var rand = Math.random()*100000;
        var id = Math.round(rand);
        return id;
    }

    function termDateChange(date) {
        
        // termdate
        setTermDate(date);
        // periods
        var id = getPeriodId();
        var fodselStartDate = shiftDate(date, -3);
        fodselStartDate.setDate(fodselStartDate.getDate()+1);
        var fodsel = buildPeriod(id, 'Fødselsperm', fodselStartDate, 3, 'fodsel');
        var dur; 
        if (model100) dur = perm100Grenser.morUker
        else dur = perm80Grenser.morUker
        var mamma = buildPeriod(getPeriodId(), 'Mammaperm', getNextDate(fodsel), dur, 'mamma');
        if (model100) dur = perm100Grenser.fellesUker
        else dur = perm80Grenser.fellesUker
        var felles = buildPeriod(getPeriodId(), 'Fellesperm', getNextDate(mamma), dur, 'felles');
        if (model100) dur = perm100Grenser.farUker
        else dur = perm80Grenser.farUker
        var pappa = buildPeriod(getPeriodId(), 'Pappaperm', getNextDate(felles), dur, 'pappa');
        var initialPeriods = [fodsel, mamma, felles, pappa];
        setPeriods(initialPeriods);
        refreshSections(initialPeriods);


        // sections
        //var newSections = getPermSections(period);
        //setSections(newSections);

        previousYear = date.getUTCFullYear()-1;
        permYear = date.getUTCFullYear();
        nextYear = date.getUTCFullYear()+1;
        nextNextYear = date.getUTCFullYear()+2;
        updateMonthOrder(fodsel.startDate.getMonth());
    }

    function addSection(duration, label, value) {
        var id = getPeriodId();
        var startDate = getNextDate(periods[periods.length-1]);

        var period = buildPeriod(id, label, startDate, duration, value);
        var updatedPeriods = [...periods];
        updatedPeriods.push(period);
        setPeriods(updatedPeriods);

        var newSections = getPermSections(period);
        var updatedSections = [...sections];
        updatedSections.push(...newSections);
        setSections(updatedSections);
    }
    
    function shufflePeriod(direction, periodId){
        if (periods.length<3) return;
        var before = [...periods];
        var switchThem;
        var after;
        var theSwitch = [];
        var switchNumber = before.map(function(e) { return e.id; }).indexOf(periodId);
        if (switchNumber == 0) return; 
        if (direction === 'up') {
            if (switchNumber < 2) return;
            switchThem = before.splice(switchNumber-1);
            after = switchThem.splice(2)
        } else if (direction === 'down'){
            if (switchNumber > periods.length-2) return; 
            switchThem = before.splice(switchNumber);
            after = switchThem.splice(2);
        } else return; 

        var first = switchThem[0];
        var second = switchThem[1];
        var firstPeriod = buildPeriod(second.id, second.shortName, first.startDate, second.duration, second.type);
        var secondStartDate = getNextDate(firstPeriod);
        var secondPeriod = buildPeriod(first.id, first.shortName, secondStartDate, first.duration, first.type);
        theSwitch.push(firstPeriod);
        theSwitch.push(secondPeriod);

        var updatedPeriods = [];
        updatedPeriods.push(...before);
        updatedPeriods.push(...theSwitch);
        updatedPeriods.push(...after);
        setPeriods(updatedPeriods);
        refreshSections(updatedPeriods);
    }

    function deletePeriod(periodId) {
        if (periods.length<2) return; 
        var updatedPeriods = [periods[0]];
        for (let i=1; i<periods.length; i++){
            var per = periods[i];
            if (per.id !== periodId){
                var startDate = getNextDate(updatedPeriods[updatedPeriods.length-1]);
                var period = buildPeriod(per.id, per.shortName, startDate, per.duration, per.type);
                updatedPeriods.push(period);
            }
        }
        setPeriods(updatedPeriods);
        refreshSections(updatedPeriods);
    }

    function refreshSections(updatedPeriods){
        var updatedSections = [];
        for (let per of updatedPeriods){
            var newSections = getPermSections(per);
            updatedSections.push(...newSections);
        }
        setSections(updatedSections);
    }

    function switchTo80(){
        setModel100(false);
        if (!periods.length) return; 
        var oldPeriods = [...periods];
        var updatedPeriods = [periods[0]];
        for (let i = 0; i<oldPeriods.length; i++){
            var p = oldPeriods[i];
            if (p.type === 'fodsel') continue;
            else {
                var startDate = getNextDate(updatedPeriods[updatedPeriods.length-1]);
                var period;
                if (p.type === 'mamma' && p.duration === perm100Grenser.morUker){
                    period = buildPeriod(p.id, p.shortName, startDate, perm80Grenser.morUker, p.type);
                } else if (p.type === 'pappa' && p.duration === perm100Grenser.farUker) {
                    period = buildPeriod(p.id, p.shortName, startDate, perm80Grenser.farUker, p.type);
                } else if (p.type === 'felles' && p.duration === perm100Grenser.fellesUker){
                    period = buildPeriod(p.id, p.shortName, startDate, perm80Grenser.fellesUker, p.type);
                } else {
                    period = buildPeriod(p.id, p.shortName, startDate, p.duration, p.type);
                }
                updatedPeriods.push(period);
            } 
        }
        setPeriods(updatedPeriods);
        refreshSections(updatedPeriods);

    }

    function switchTo100(){
        setModel100(true);
        if (!periods.length) return; 
        var oldPeriods = [...periods];
        var updatedPeriods = [periods[0]];
        for (let i = 0; i<oldPeriods.length; i++){
            var p = oldPeriods[i];
            if (p.type === 'fodsel') continue;
            else {
                var startDate = getNextDate(updatedPeriods[updatedPeriods.length-1]);
                var period;
                if (p.type === 'mamma' && p.duration === perm80Grenser.morUker){
                    period = buildPeriod(p.id, p.shortName, startDate, perm100Grenser.morUker, p.type);
                } else if (p.type === 'pappa' && p.duration === perm80Grenser.farUker) {
                    period = buildPeriod(p.id, p.shortName, startDate, perm100Grenser.farUker, p.type);
                } else if (p.type === 'felles' && p.duration === perm80Grenser.fellesUker){
                    period = buildPeriod(p.id, p.shortName, startDate, perm100Grenser.fellesUker, p.type);
                } else {
                    period = buildPeriod(p.id, p.shortName, startDate, p.duration, p.type);
                }
                updatedPeriods.push(period);
            } 
        }
        setPeriods(updatedPeriods);
        refreshSections(updatedPeriods);
    }

    function editPeriod(periodId, newDuration, newType, newName) {
        var oldPeriods = [...periods];
        var updatedPeriods = [periods[0]];
        for (let i = 0; i<oldPeriods.length; i++){
            var per = oldPeriods[i];
            if (per.type === 'fodsel') continue;
            else if (per.id != periodId){
                var startDate = getNextDate(updatedPeriods[updatedPeriods.length-1]);
                var period = buildPeriod(per.id, per.shortName, startDate, per.duration, per.type);
                updatedPeriods.push(period);
            } else {
                var startDate = getNextDate(updatedPeriods[updatedPeriods.length-1]);
                console.log(newName);
                var period = buildPeriod(per.id, newName, startDate, newDuration, newType);
                updatedPeriods.push(period);
            }
        }
        setPeriods(updatedPeriods);
        refreshSections(updatedPeriods);
    }
    

    return(<>
        <PlaningViewContainer>
            <PlaningEditorContainer color={props.themeColor.secondary}>
                <H4 style={{marginBottom: "16px"}}>Start med å sette en termindato og velg 100% eller 80% utbetaling</H4>
                <DateSelector
                    onChange={termDateChange}
                />
                <RadioCheckboxContainer>
                    <H5>Velg en utbetalingsmodel: </H5>
                    <Checkbox color={props.themeColor.tertiary} label={"100%"} checked={(model100===true)} onClick={switchTo100}/>
                    <Checkbox color={props.themeColor.tertiary} label={"80%"} checked={(model100===false)} onClick={switchTo80}/>
                </RadioCheckboxContainer>
                {(termDate==='')?<></>:
                    <AddSectionTool
                        color={(termDate==='')?{tertiary: "#d3d3d3"}:props.themeColor}
                        updateMonths={updateMonthOrder}
                        sections = {sections}
                        setSections={setSections}
                        periods={periods}
                        setPeriods={setPeriods}
                        getPeriodId={getPeriodId}
                        addSection={addSection}
                        model100={model100}
                        setShowPlannerInfo={setShowPlannerInfo}
                    />}
                    {periods? <>
                        {periods.map(period => {
                            var color = getPermColor(period.type);
                            return(
                                <Period
                                    key={period.id}
                                    period={period}
                                    shufflePeriod={shufflePeriod}
                                    deletePeriod={deletePeriod}
                                    color={color}
                                    editPeriod={editPeriod}
                                />
                            )
                        })}
                    </>: <></>}
            </PlaningEditorContainer>
            <Calendar> 
                {months.map((month, ix) => {
                    var col = 1;
                    var row;
                    if ((month.number % 2) === 0) {
                        row = month.number/2 + 1;
                    } else {
                        col = 2;
                        row = (month.number-1)/2 + 1;
                    }

                    var monthSections;
                    if (sections.length){
                        monthSections = sections.filter(sec => (sec.startDate.getMonth() === month.calendarNumber && sec.startDate.getFullYear() === month.year));
                    }

                    var mnt = month; 
                    var leapYear = ((month.year % 4 == 0) && (month.year % 100 != 0)) || (month.year % 400 == 0)
                    if (leapYear && month.name==='Februar') mnt.numberOfDays = 29;

                    var checkDate = new Date(month.year, month.calendarNumber, 15, 13);

                    var addTermDate = false; 
                    if (termDate !== ''){
                        if (termDate.getDate() === 1 && getMonthDiff(termDate, checkDate) === 0) {
                            addTermDate = true; 
                        }
                    }

                    return(
                        <Month
                            key={ix}
                            col={col}
                            row={row}
                            monthName={month.name}
                            color={props.themeColor.secondary}
                            fr={mnt.numberOfDays}
                            year= {month.year}
                            sections={monthSections}
                            termDate={termDate}
                            checkDate={checkDate}
                            addTermDate = {addTermDate}
                            age={month.number-1}
                        >
                        </Month>
                    )
               })}
            </Calendar>
        </PlaningViewContainer>
        <Modal show={showPlannerInfo} onClick={() => setShowPlannerInfo(false)}>
            <InnerModalPermSections/>
        </Modal>
    </>);
}

export default PlanningView;