import styled from "styled-components";
import { borderRaduis, font, color } from "../../theme/standardTheme";

const TopicButton = styled.button`
    height: 30px; 
    width: 80px;
    border: none;
    border-radius: ${borderRaduis.small};
    background-color: ${props => props.color? props.color : "red"};
    color: ${color.fontSecondary};
    font-family: ${font};
    font-weight: bold;

    &:hover {
        background-color: ${props => props.hoverColor? props.hoverColor : color.burgundy};
        scale: 1.02;
        
    }
`
//box-shadow: 0px 0px 5px 2px ${props => props.shadow? props.shadow : "white"};

export default TopicButton;