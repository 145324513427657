import styled from "styled-components";

const MonthContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.fr? props.fr: 31}, 1fr);
    grid-template-rows: 20px 15px 30px;
    grid-auto-columns: 1fr;
    border: 2px solid ${props => props.color? props.color : "red"};

    grid-column-start: ${props => props.col? (props.col) : "1"};
    grid-column-end: ${props => props.col? (props.col+1) : "2"};
    grid-row-start: ${props => props.row? (props.row) : "1"};
    grid-row-end: ${props => props.row? (props.row+1) : "2"};
`

export default MonthContainer;