import React from "react";
import InnerModalContainer from "../containers/InnerModalContainer";
import { H4, H5, P } from "../theme/text";
import { color } from "../theme/standardTheme";

const InnerModalPermSections = (props) => {

    return(
        <InnerModalContainer>
                <H4>Hvordan planlegge permisjonen deres</H4>
                <P>
                    Det er mange ting man må ta hensyn til når man planlegger foreldrepermisjon.
                </P>
                <P>
                    Det mange bruker som utgangspunkt er at man ønsker at tiden skal strekke til helt til man har barnehageplass. 
                    For de fleste er ikke permisjonstiden i seg selv nok og da hjelper det å spe på med ferie og ulønnet permisjon. 
                </P>
                <P>
                    Når man har krav på barnehageplass kommer ann på når barnet er født.
                    Barn som fyller 1. år innen august har krav på barnehageplass fra August. 
                    Dersom barnet fyller 1 år etter August har man krav på plass innen utgangen av den måneden barnet fyller 1. år. 
                </P>
                <H5>Så hvordan går man frem?</H5>
                <P>
                    Start med å tenke på hva dere har til rådighet av ferie og hvordan dere kan bruke denne.
                    Skal dere ha felles ferier eller mest mulig hver for dere slik at man kan dekke en lengere periode. 
                </P>
                <P>
                    De fleste har krav på 5 uker ferie hvert år, som vil si at dersom man er to har man 10 uker ferie til rådighet. 
                    Disse kommer veldig godt med når man skal planlegge permisjonen og kan ofte hjelpe veldig med å få tiden til å strekke helt til man har barnehageplass. 
                    
                </P>
                <P>
                    Husk også at begge foreldre har 5 uker på hver side av nyttår og siden de fleste permisjonsperioder
                    strekker seg over nyttår har man har totalt 20 uker med ferie man kan fordele slik man ønsker. 
                    Et vanlig tips er at mor "sparer" sin ferietid det året barne fødes slik at mammapermen kan forlenges med 5 uker, hvis man bruker hele ferien sin slik det året. 
                </P>
                <P>
                    Alle er forskjellige og har ulike behov, her har vi laget et verktøy slik at dere kan leke med forskjelligje løsninger slik at dere
                    finner deres drømmepermisjon.
                    Les gjerne artikkelen vår om hvordan man kan fordele permisjonstiden, ferie og ulønnet permisjon.
                </P>
                <a style={{fontWeight: "bold", color: color.font, marginTop: "20px" }} href="https://www.foreldrepenger.no/lønn-og-permisjonspenger" target="_blank">Les mer</a>
            </InnerModalContainer>
    )
}

export default InnerModalPermSections;