import styled from "styled-components";
import { color, font } from "../theme/standardTheme";

const PermSectionBar = styled.div`
    display: flex; 
    height: 20px;
    justify-content: center; 
    align-items: center; 
    font-size: 10px; 
    font-weight: bold; 
    background-color: ${props => props.color? props.color: "red"};
    grid-column-start: ${props => props.colStart? props.colStart: 1};
    grid-column-end: ${props => props.colEnd? (props.colEnd+1): 10};
    grid-row-start: 3;
    grid-row-end:  4;
    justify-self: stretch; 
    align-self: center;
    border-top-left-radius: ${props => props.radiusLeft? "10px" : "0px"};
    border-bottom-left-radius: ${props => props.radiusLeft? "10px" : "0px"};
    border-top-right-radius: ${props => props.radiusRight? "10px" : "0px"};
    border-bottom-right-radius: ${props => props.radiusRight? "10px" : "0px"};
    font-family: ${font};
    color: ${color.fontSecondary}
`

//color: ${color.font};
//font-family: ${font}

export default PermSectionBar;