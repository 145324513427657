import styled from "styled-components";
import { color, font  } from "../../theme/standardTheme";

const DateNumber = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center; 

    grid-column-start: ${props => props.col? props.col : 1};
    grid-column-end: ${props => props.col? (props.col+1) : 1};
    gird-row-start: 2; 
    grid-row-end: 3; 

    align-self: stretch;
    justify-self: stretch;
    font-size: 9px; 
    border: 0.5px solid ${props => props.color? props.color : "red"};
    border-bottom: 1px solid ${props => props.color? props.color : "red"};
    color: ${color.fontSecondary};
    font-family: ${font};
     
`
export default DateNumber;