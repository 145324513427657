import styled from "styled-components";
import { color, font } from "./standardTheme";

const H4 = styled.h4`
    margin: 8px 0;
    margin-top: 0;
    color: ${color.font};
    font-family: ${font};

`
const H5 = styled.h5`
    margin: 8px 0;
    margin-bottom: 0;
    color: ${color.font};
    font-family: ${font};
`

const P = styled.p`
    font-size: 14px;
    margin: 8px 0;
    color: ${color.fontSecondary};
    font-family: ${font};
`

const Label = styled.label`
    color: ${color.font};
    font-family: ${font};
`

const UL = styled.ul`
    margin: 6px 20px ;
    padding: 0px 0px 0px 20px;
`
const LI = styled.li`
    font-size: 14px;
    margin: 8px;
    color: ${color.fontSecondary};
    font-family: ${font};
`

export { H4, P, H5, UL, LI }