import React from "react";
import styled from "styled-components";
import { color } from "../theme/standardTheme";

const thumb = {
    width: 20,
    height: 20,
}
const margin = Math.round(thumb.height/4)

const StyledSlider = styled.input`
    -webkit-appearance: none;
    appearance: none;
    display: block;
    appearance: none;
    width: 90%;
    max-width: 400px; 
    cursor: pointer;
    margin: ${margin}px;
    background-color: rgb(0,0,0,0);

    &::-webkit-slider-runnable-track {
        width: 100%;
        height:10px;
        background: ${color.burgundy}; 
        border-radius: 5px;
    }

    &::-webkit-slider-thumb {
        position: relative;
        appearance: none;
        height: ${thumb.height}px;
        width: ${thumb.width}px;
        background-size: cover;
        border-radius: 50%;
        border: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: background-color 150ms;
        background-color: ${color.termMarker};
    }
`

const Slider = (props) => {

    return (
        <StyledSlider
            type = 'range'    
            onChange={(event) => props.update(event.target.value)}
            onMouseUp={(event) => props.update(event.target.value)}
            onTouchEnd={(event) => props.update(event.target.value)}
            {...props}
        /> 
    )
}

export default Slider;
