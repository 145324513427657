import styled from "styled-components";

const MenuContainer = styled.div`
    display: flex;
    justify-content: center; 
    align-items: center;
    gap: 50px;
    max-width: 100vw;
    background-color: ${props => props.color? props.color: "red"};
     
    padding: 5px;
`

export default MenuContainer;