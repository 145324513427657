import React from "react";
import styled from "styled-components";

const AppContainer = styled.div`
    display: grid; 
    grid-template-rows: auto 1fr;
    background-color: ${props => props.color? props.color: "red"};
`

export default AppContainer;
